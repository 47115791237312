


















































































.countdown-time{
  font-size: 2.5em;
  font-weight: bold;
  small{
    font-size: .35em;
  }
  .colon{
    min-width:20px;
    text-align: center;
  }
}
